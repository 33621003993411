<!--
 * @Author: a3802 253092329@qq.com
 * @Date: 2024-08-29 04:06:07
 * @LastEditors: a3802 253092329@qq.com
 * @LastEditTime: 2024-12-11 01:34:55
 * @FilePath: \luckycanvas\src\views\refund\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
        <div class="content">
            <div class="refund">
                <div class="bg setion_one">
                    <img src="https://image.hrzhuolin.com/static/png/bg_refund-D40Ygn-z.png">
                    <div class="his_return">
                        <router-link :to="{ path: '/', query: { sn: orderId } }" active-class="nav-color">
                            <span class="icon">
                                <font-awesome-icon icon="angle-left" />
                            </span>
                            <span>返回</span>
                        </router-link>
                    </div>
                </div>
                <div class="setion_two">
                    <van-form @submit="onSubmit">
                        <div class="subtitle">商家订单号</div>
                        <div class="desc">在支付订单页面可找到该单号，具体看下方教程。</div>
                        <input class="order_num" placeholder="请输入商家订单号" type="text" v-model="order_str" />
                        <button class="btn_refund" type="submit">立即退款</button>
                    </van-form>
                    <div class="contact">
                        
                        <a href="tel:01088415010">
                            <img src="https://image.hrzhuolin.com/static/png/btn_tel-C6j6GYPt.png">
                        </a>
                        <a href="https://tb.53kf.com/code/client/818133c55cba154d3d56d99366e4b4475/1">
                            <img src="https://image.hrzhuolin.com/static/png/btn_kf-BAVxk4vl.png">
                        </a>
                    </div>
                    <div class="notice">
                         * 提交退款申请后1天内款项将原路返回;
                        <br>
                         * 退款成功后，您购买的权益服务将立即失效.
                    </div>
                </div>
                <div class="section_three">
                    <div class="th_tits">如何找到商家订单号？</div>
                    <img src="https://image.hrzhuolin.com/static/png/exp_1-CZr3CSkH.jpg">
                    <img src="https://image.hrzhuolin.com/static/png/exp_2-kjXxlLke.jpg">
                    <img src="https://image.hrzhuolin.com/static/png/exp_3-DMWzN27T.jpg">

                </div>
            </div>
        </div>
</template>

<script>
import { reactive,ref } from 'vue';
import { Toast } from 'vant';
import * as Index from '../../api/index';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas);

export default {

    setup() {


        const order_str = ref('');
		const order = reactive({ 
                form: {
                    order_sn: ''                    
                }
        });

		        // 取url中的参数值
        const getQuery = (name) => {
            let geturl = window.location.href
            let getqyinfo = geturl.split('?')[1]
            let params = new URLSearchParams('?' + getqyinfo);
            return params.get(name);
        };

        const orderId = getQuery('sn');
        
        const onSubmit = () => {
            // order.form.order_sn = order_str.value

            if (!order_str.value) {  
				// 请输入订单号！  
				Toast('请输入订单号！');
				return;  
            }

                Toast.loading({
                    message: '',
                    forbidClick: true,
                    overlay: true,
                    iconSize: '45',
                    duration: 5000
                });
            

            Index.refund({'order_sn':order_str.value}).
                then(result => {
                    Toast.clear();
                    if (result.status == 500) {

                        Toast(result.message)
                        return false;

                    }else{
                        Toast('退款申请提交,请耐心等待');
                    }
                })
        }


        return {
            onSubmit,
            order,
            order_str,
            orderId
        };
    }
  

}


</script>
